import AppLayout, {
  reportWebVitals,
} from '@speedwaymotors/clutch/Layout/Next/_app'

import withTracer from '../Utilities/withTracer'

const TracedAppWithCustomLayout = withTracer(AppLayout, 'PrpApp')

export { reportWebVitals }
export default TracedAppWithCustomLayout
