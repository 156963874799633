let tracer = null

if (typeof window === 'undefined') {
  tracer = require('../instrumentation.node').tracer
}

const withTracer = (Component, componentName) => {
  const TracedComponent = (props) => {
    let span
    if (tracer) {
      span = tracer.startSpan(`render:${componentName}`)
    }

    const render = Component(props)

    if (span) {
      span.end()
    }

    return render
  }

  TracedComponent.displayName = `WithTracer(${componentName})`

  return TracedComponent
}

export default withTracer
