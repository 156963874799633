export const AppName = 'Prp'
export const FeatureFlags = [
  'PRP_SPA',
  'PRP_Default_Grid_Layout',
  'PRP_Mobile_Facet_Selection',
  'Add_To_List_Badge_Expanded',
  'PRP_Vehicle_Top_Section_Enabled',
  'Product_Card_Share',
  'PRP_SearchProviderSwitch',
  'Ribbon_Text_Dollar_Vs_Percentage',
  'PRP_FrontEnd_Promo_Preview',
  'PRP_FitmentSectionInteractivity',
]
export const DisableOldVehicleContext = true
